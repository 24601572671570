import 'reflect-metadata';
import RAFLoginUserContextProvider from './RAFAuthentication/RAFLoginUserContextProvider';
import RAFRolePermissionsContextProvider from './RAFAuthentication/RAFRolePermissionsContextProvider';
import RequestInterceptor from './RAFComponents/services/axios/RequestInterceptor';
import MyTeamsContextProvider from './RAFModules/Common/Providers/MyTeamsContextProvider';
import RAFNavigationContextProvider from './RAFModules/Common/Providers/RAFNavigationContextProvider';
import WorkspaceContextProvider from './RAFModules/Common/Providers/WorkspaceContextProvider';
import NavigationMenuTreeView from "./components/shared/NavigationMenu/NavigationMenuTreeView/NavigationMenuTreeView";
import RAFFooterNavigationMenu from "./components/shared/NavigationMenu/RAFFooterNavigationMenu";
import { backDropToggle } from './helpers/ACutils';
import { AppRouter } from "./router";

function AppAuthenticated() {

    const IsBasePage = (): boolean => {
        let retVal: boolean = false;
        const windowLocationPathname = window.location.pathname.toLowerCase();
        switch (windowLocationPathname) {
            case "/login":
            case "/login/":
            case "/loginnew":
            case "/loginnew/":
            case "/logout":
            case "/logout/":
            case "/signout":
            case "/signout/":
            case "/activate":
            case "/activate/":
            case "/invite":
            case "/invite/":
            case "/authsuccess":
            case "/authsuccess/":
                //case "/":
                retVal = true;
                break;
            default:
                break;
        }
        return retVal;
    };


    const leftNavigationMenuContent = () => {
        return (
            <NavigationMenuTreeView />
        );
    };

    const footerMenuContent = () => {
        return (
            <>

                {/* <button onClick={() => {
                    const url = new URL(window.location.href);
                    url.searchParams.set('print', 'true');
                    window.open(url.toString(), '_blank');
                }}>Print Layout</button> */}
                <RAFFooterNavigationMenu />
            </>
        );
    };


    const isBasePage: boolean = IsBasePage();

    return (
        <RequestInterceptor>
            <RAFLoginUserContextProvider
            >
                <RAFRolePermissionsContextProvider>

                    <MyTeamsContextProvider>
                        <RAFNavigationContextProvider>
                            <WorkspaceContextProvider>
                                <>
                                    <div className="row mainpage-container h-100 g-0 flex-nowrap">
                                        {!isBasePage &&
                                            <div className="col-auto h-100 navigationMenu-col">
                                                {leftNavigationMenuContent()}
                                            </div>}
                                        <main className="col page-wrapper h-100 overflow-hidden" id="mainTabDiv"> {/* overflow-hidden added for fix the wi-5885 */}
                                            <div className="section__container position-relative" style={{ zIndex: "200" }}>
                                                <div
                                                    className="transparentBackdrop"
                                                    onClick={backDropToggle}
                                                >
                                                </div>
                                                <div className="section__div">
                                                    <AppRouter />
                                                </div>
                                                {footerMenuContent()}

                                            </div>
                                        </main>
                                    </div>
                                </>
                            </WorkspaceContextProvider>
                        </RAFNavigationContextProvider>
                    </MyTeamsContextProvider>
                </RAFRolePermissionsContextProvider>
            </RAFLoginUserContextProvider>
        </RequestInterceptor>
    );
}

export default AppAuthenticated;

