import React from "react";
import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useAuthenticator } from "@aws-amplify/ui-react";


export function RAFAuthenticatedTemplate({ children }): React.ReactElement | null {

    const isAuthenticated = useIsAuthenticated();
    const context = useMsal();
    const { authStatus } = useAuthenticator((context) => [context.user]);

    if ((isAuthenticated || authStatus === 'authenticated') && context.inProgress !== InteractionStatus.Startup) {
        return (
            <React.Fragment>
                {
                    (typeof children === "function") ? children(context) : children
                }
            </React.Fragment>
        );
    }
    return null;
}